
import { Vue, Component, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
@Component
export default class Auth0Callback extends Vue {
  private authHandled = false
  hasError: boolean = false
  errorMessage: string = ''

  @Watch('$auth0.loading', { immediate: true })
  onAuth0LoadingChanged(loading: boolean) {
    if (loading) {
      return
    }
    if (!loading && !this.authHandled) {
      this.authHandled = true // Ensure we don't handle the callback more than once
      this.handleRedirectCallback()
    }
  }

  async handleRedirectCallback() {
    this.hasError = false
    this.errorMessage = ''
    try {
      const appState = await this.$auth0.handleRedirectCallback()
      const { redirectFrom, routeName } = appState || {
        redirectFrom: null,
        routeName: 'home',
      }
      const accessToken = await this.$auth0.getTokenSilently()
      await this.$store.dispatch('auth/auth0Login', accessToken)
      this.$router.push((redirectFrom as RawLocation) || { name: routeName })
    } catch (error: any) {
      console.error('Error handling redirect:', error)
      this.errorMessage = error?.response?.data?.message || ''
      this.hasError = true
      this.authHandled = false // Allow retrying if the first attempt fails
    }
  }

  async logOut() {
    await this.$store.dispatch('auth/logOut')
  }
}
